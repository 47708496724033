import { inject, injectable } from 'inversify'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'
import {
  NotAllowedException,
  NotFoundException,
  NotSupportedException,
} from '~/types/portal'

type GetResponseBody = string

export enum ConfigurationTextEndpoint {
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_SERVICE = 'terms-of-service',
  IMPRINT = 'imprint',
}

@injectable()
export default class ConfigurationTextApiService {
  public constructor(
    @inject(SYMBOLS.Axios) private readonly axios: AxiosInstance
  ) {}

  public get(
    endpoint: ConfigurationTextEndpoint,
    language: string
  ): Promise<string> {
    return this.axios
      .get(`/configuration/${endpoint}/${language}`)
      .then((response: AxiosResponse<GetResponseBody>) =>
        Promise.resolve(response.data)
      )
      .catch((e: AxiosError) => {
        const status = e.response?.status
        if (status === 404) {
          return Promise.reject(new NotFoundException())
        }

        return Promise.reject(e)
      })
  }

  public update(
    endpoint: ConfigurationTextEndpoint,
    language: string,
    text: string
  ): Promise<void> {
    if (endpoint === ConfigurationTextEndpoint.TERMS_OF_SERVICE) {
      throw new NotSupportedException()
    }

    return this.axios
      .post(`/configuration/${endpoint}/${language}`, text, {
        headers: {
          'content-type': 'text/markdown',
        },
      })
      .then(() => Promise.resolve())
      .catch((e: AxiosError) => {
        const status = e.response?.status
        if (status === 403) {
          return Promise.reject(new NotAllowedException())
        }

        return Promise.reject(e)
      })
  }
}
