import Vue from 'vue'
import { hydrate, QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import type { Plugin } from '@nuxt/types'

const plugin: Plugin = (context) => {
  const queryClient = new QueryClient({})
  const options = { queryClient }

  if (process.client) {
    Vue.use(VueQueryPlugin, options)

    if (context.nuxtState && context.nuxtState.vueQueryState) {
      hydrate(queryClient, context.nuxtState.vueQueryState)
    }
  }
}

export default plugin
